<template>
  <div>
    <b-card>
      <div class="overflow-auto">
        <b-row>
          <b-col sm="12" md="8">
            <b-button
              variant="primary"
              @click="openFormCreateRecipients"
              class="mr-1 mb-1"
              >Crear nuevo</b-button
            >
            <b-button
              variant="info"
              @click="showModalEditAlert = true"
              class="mr-1 mb-1"
              >Editar</b-button
            >
            <b-button
              variant="danger"
              @click="ConfirmDeleteRecipient"
              class="mr-1 mb-1"
              >Eliminar</b-button
            >
          </b-col>
          <b-col sm="12" md="2">
            <b-form-group
              label-for="i-perPage"
              label="Items por página"
              label-cols="7"
            >
              <b-form-input id="i-perPage" type="number" v-model="perPage" />
            </b-form-group>
          </b-col>
          <b-col csm="12" md="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="right"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </b-row>
        <br />
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          select-mode="single"
          responsive="sm"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <b-button
                variant="success"
                class="btn-icon rounded-circle"
                size="sm"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <!-- <span class="sr-only">Selected</span> -->
            </template>
            <template v-else>
              <b-button
                variant="outline-dark"
                class="btn-icon rounded-circle"
                size="sm"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <!-- <span class="sr-only">Not selected</span> -->
            </template>
          </template>

          <template #cell(Activo)="data">
            <b-badge variant="info">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(SubCasillero)="data">
            <b-badge variant="warning">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
        <br />
      </div>
    </b-card>
    <!-- Inicio modal editar destinatario -->
    <b-modal
      v-model="showModalEditAlert"
      cancel-title="Cancelar"
      ok-title="Guardar"
      cancel-variant="danger"
      id="modalEditRecipient"
      title="Editar Destinatario"
      size="lg"
      @ok="onFormEditButtonClick"
    >
      <validation-observer ref="editRules">
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="i-identificationType"
              label="Tipo de identificación"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo_de_identificación"
                rules="required"
              >
                <b-form-select
                  id="i-identificationType"
                  placeholder=""
                  v-model="formEditData.identificationTypeId"
                  :options="optionsIdentificationType"
                  @change="getDepartmentsFromService()"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="i-identificationNumber"
              label="Nit o número de identificación"
            >
              <validation-provider
                #default="{ errors }"
                name="Numero_de_identificación"
                rules="required"
              >
                <b-form-input
                  id="i-identificationNumber"
                  type="text"
                  placeholder=""
                  v-model="formEditData.identificationNumber"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-firstName" label="Primer nombre">
              <validation-provider
                #default="{ errors }"
                name="Primer_nombre"
                rules="required"
              >
                <b-form-input
                  id="i-firstName"
                  type="text"
                  placeholder=""
                  v-model="formEditData.firstname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-secondName" label="Segundo nombre">
              <b-form-input
                id="i-secondName"
                type="text"
                placeholder=""
                v-model="formEditData.secondname"
              />
            </b-form-group>
          </b-col> </b-row
        ><b-row>
          <b-col md="6">
            <b-form-group label-for="i-firstLastname" label="Primer apellido">
              <validation-provider
                #default="{ errors }"
                name="Primer_apellido"
                rules="required"
              >
                <b-form-input
                  id="i-firstLastName"
                  type="text"
                  placeholder=""
                  v-model="formEditData.firstLastname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-secondLastname" label="Segundo apellido">
              <b-form-input
                id="i-secondLastName"
                type="text"
                placeholder=""
                v-model="formEditData.secondLastname"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-country" label="País">
              <validation-provider
                #default="{ errors }"
                name="Pais"
                rules="required"
              >
                <b-form-select
                  id="i-country"
                  placeholder=""
                  v-model="formEditData.countryId"
                  :options="optionsCountry"
                  @change="
                    getDepartmentsFromService(formEditData.countryId, null)
                  "
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-department" label="Departamento">
              <validation-provider
                #default="{ errors }"
                name="Departamento"
                rules="required"
              >
                <b-form-select
                  id="i-department"
                  placeholder=""
                  v-model="formEditData.departmentId"
                  :options="optionsDepartment"
                  @change="
                    getCitiesFromService(formEditData.departmentId, null)
                  "
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-city" label="Ciudad">
              <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
              >
                <b-form-select
                  id="i-city"
                  placeholder=""
                  v-model="formEditData.cityId"
                  :options="optionsCity"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-neighborhood" label="Barrio/Colonia">
              <validation-provider
                #default="{ errors }"
                name="Barrio"
                rules="required"
              >
                <b-form-input
                  id="i-neighborhood"
                  type="text"
                  placeholder=""
                  v-model="formEditData.neighborhood"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-address" label="Dirección">
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                <b-form-input
                  id="i-address"
                  type="text"
                  placeholder=""
                  v-model="formEditData.address"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="i-email"
                  type="text"
                  placeholder=""
                  v-model="formEditData.email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-phone" label="Teléfono fijo">
              <b-form-input
                id="i-phone"
                type="text"
                placeholder=""
                v-model="formEditData.phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-cellphone" label="Teléfono celular">
              <validation-provider
                #default="{ errors }"
                name="Telefono_celular"
                rules="required"
              >
                <b-form-input
                  id="i-cellphone"
                  type="text"
                  placeholder=""
                  v-model="formEditData.cellphone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.active"
            >
              Activo
            </b-form-checkbox>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <!-- Fin modal editar destinatario -->
  </div>
</template>

<script>
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BButton,
  BButtonGroup,
  BTable,
  BPagination,
  BIcon,
  BModal,
} from "bootstrap-vue";
export default {
  name: "destinatarios",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    BButtonGroup,
    BTable,
    BPagination,
    BIcon,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: [],
      perPage: 10,
      currentPage: 1,
      items: [],
      selectedRow: {},
      showModalEditAlert: false,
      fields: [
        { key: "selected", label: "" },
        "Activo",
        "SubCasillero",
        "Nombre",
        "Direccion",
        "Pais",
        { key: "DepartamentoEstado", label: "Dpto / Estado" },
        "Ciudad",
        "Telefono",
      ],
      optionsCountry: [],
      optionsDepartment: [],
      optionsCity: [],
      optionsIdentificationType: [],
      formEditData: {
        identificationTypeId: "",
        identificationNumber: "",
        countryId: "",
        departmentId: "",
        cityId: "",
        firstname: "",
        secondname: "",
        firstLastname: "",
        secondLastname: "",
        cellphone: "",
        address: "",
        email: "",
        phone: "",
        postcodeId: "",
        neighborhood: "",
        subcasillero: "",
        active: true,
      },
    };
  },
  created() {
    this.getRecipientsFromService();
    this.getIdentificationsTypeFromService();
    this.getCountriesFromService();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    onRowSelected(items) {
      this.selectedRow = items[0];
      console.log(this.selectedRow);
      this.formEditData.active = false;
      this.formEditData.identificationTypeId = this.selectedRow.tipoIdentificacion;
      this.formEditData.identificationNumber = this.selectedRow.numeroIdentificacion;
      this.formEditData.firstname = this.selectedRow.primerNombre;
      this.formEditData.secondname = this.selectedRow.segundoNombre;
      this.formEditData.firstLastname = this.selectedRow.primerApellido;
      this.formEditData.secondLastname = this.selectedRow.segundoApellido;
      this.formEditData.neighborhood = this.selectedRow.original.barrio;
      this.formEditData.address = this.selectedRow.original.direccion;
      this.formEditData.email = this.selectedRow.original.email;
      this.formEditData.phone = this.selectedRow.original.telefono_fijo;
      this.formEditData.cellphone = this.selectedRow.original.telefono_celular;
      this.formEditData.active = this.selectedRow.original.activo ? 1 : 0;
      this.formEditData.subcasillero = this.selectedRow.original.sub_casillero;
      this.getCountriesFromService(this.selectedRow.original.pais_codigo);
    },
    getRecipientsFromService() {
      let str = `Bearer ${this.$session.get("userData").access_token}`;
      this.$http
        .post(
          `api/casillero/clientes/destinatarios`,
          {
            pageSize: this.perPage,
            str_busqueda: "",
          },
          { headers: { Authorization: str } }
        )
        .then((response) => {
          console.log(response.data.datos.data);
          this.formatDataforTable(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataforTable(dataFromService) {
      this.items = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        let ciudadName = itemFromService.ciudad.nombre;
        let ciudadId = itemFromService.ciudad.id;
        let departamentoName = itemFromService.departamento.nombre;
        let departamentoId = itemFromService.departamento.id;
        let paisNombre = itemFromService.pais.nombre;
        let paisCod = itemFromService.pais.codigo;
        itemFormated = {
          original: itemFromService,
          Activo: itemFromService.activo ? "Si" : "No",
          ID: itemFromService.id,
          SubCasillero: itemFromService.sub_casillero,
          Nombre: itemFromService.nombre_completo,
          primerNombre: itemFromService.primer_nombre,
          segundoNombre: itemFromService.segundo_nombre,
          primerApellido: itemFromService.primer_apellido,
          segundoApellido: itemFromService.segundo_apellido,
          tipoIdentificacion: itemFromService.tipo_de_identificacion_id,
          numeroIdentificacion: itemFromService.numero_de_identificacion,
          Pais: paisNombre,
          paisCod: paisCod,
          DepartamentoEstado: departamentoName,
          departamentoId: departamentoId,
          Ciudad: ciudadName,
          ciudadId: ciudadId,
          Direccion: itemFromService.direccion,
          Telefono: itemFromService.telefono_celular,
        };
        formatedData.push(itemFormated);
        this.items = formatedData;
      });
    },
    openFormCreateRecipients() {
      this.$router.push({ name: "crear-destinatarios" });
    },
    getIdentificationsTypeFromService() {
      this.$http
        .get(`api/tiposdeidentificacion/${process.env.VUE_APP_APPID}`, {
          headers: {
            Authorization: `Bearer ${
              this.$session.get("userData").access_token
            }`,
          },
        })
        .then((response) => {
          this.optionsIdentificationType = [];
          response.data.datos.forEach((idType) => {
            let itemIdType = {
              value: idType.id,
              text: idType.descripcion,
            };
            this.optionsIdentificationType.push(itemIdType);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCountriesFromService(countryId) {
      this.$http
        .get(
          `api/casillero/clientes/getCountries/${process.env.VUE_APP_APPID}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          response.data.forEach((country) => {
            let itemCountry = {
              value: country.codigo,
              text: country.nombre,
            };
            this.optionsCountry.push(itemCountry);
          });
          if (countryId && countryId != null) {
            this.formEditData.countryId = countryId;
            this.getDepartmentsFromService(
              countryId,
              this.selectedRow.original.departamento_id
            );
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDepartmentsFromService(countryId, departmentId) {
      this.$http
        .get(
          `api/casillero/clientes/getDepartaments/${countryId}/${process.env.VUE_APP_APPID}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.optionsDepartment = [];
          response.data.forEach((department) => {
            let itemDepartment = {
              value: department.id,
              text: department.nombre,
            };
            this.optionsDepartment.push(itemDepartment);
          });
          if (departmentId && departmentId != null) {
            this.formEditData.departmentId = departmentId;
            this.getCitiesFromService(
              departmentId,
              this.selectedRow.original.ciudad_id
            );
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCitiesFromService(departmentId, cityId) {
      this.$http
        .get(
          `api/casillero/clientes/getCities/${departmentId}/${process.env.VUE_APP_APPID}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.optionsCity = [];
          response.data.forEach((city) => {
            let itemCity = {
              value: city.id,
              text: city.nombre,
            };
            this.optionsCity.push(itemCity);
          });
          if (cityId && cityId != null) {
            this.formEditData.cityId = cityId;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    EditRecipient() {
      this.$http
        .post(
          `api/casillero/destinatarios/${this.selectedRow.ID}`,
          {
            activo: this.formEditData.active,
            primer_nombre: this.formEditData.firstname,
            segundo_nombre: this.formEditData.secondname,
            primer_apellido: this.formEditData.firstLastname,
            segundo_apellido: this.formEditData.secondLastname,
            pais_codigo: this.formEditData.countryId,
            departamento_id: this.formEditData.departmentId,
            ciudad_id: this.formEditData.cityId,
            telefono_celular: this.formEditData.cellphone,
            direccion: this.formEditData.address,
            email: this.formEditData.email,
            tipo_de_identificacion_id: this.formEditData.identificationTypeId,
            numero_de_identificacion: this.formEditData.identificationNumber,
            telefono_fijo: this.formEditData.phone,
            codigo_postal_id: this.formEditData.postcodeId
              ? this.formEditData.postcodeId
              : null,
            barrio: this.formEditData.neighborhood,
            sub_casillero: this.formEditData.subcasillero,
            cliente_id: this.$store.state.clients.infoUser.id,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Destinatarios",
            text: "¡Destinatario editado con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            this.showModalEditAlert = false;
            this.getRecipientsFromService();
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Destinatarios",
            text: "Error al editar el destinatario",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            this.getRecipientsFromService();
          });
        });
    },
    ConfirmDeleteRecipient() {
      this.$swal({
        title: "¿Deseas eliminar el destinatario seleccionado?",
        text: "¡Recuerda que esta operación no es reversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-danger mr-2",
          cancelButton: "btn btn-primary",
        },
        buttonsStyling: true,
      }).then((result) => {
        if (result.value) {
          this.DeleteRecipient();
        }
      });
    },
    DeleteRecipient() {
      let str = `Bearer ${this.$session.get("userData").access_token}`;
      this.$http
        .delete(`api/casillero/destinatarios/${this.selectedRow.ID}`, {
          headers: { Authorization: str },
        })
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Destinatarios",
            text: "Destinatario eliminado con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            this.getRecipientsFromService();
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.$swal({
            title: "Destinatarios",
            text: "Error al eliminar destinatario",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    validationFormEdit() {
      return new Promise((resolve, reject) => {
        this.$refs.editRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFormEditButtonClick(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormEdit().then((validForm) => {
        if (validForm) {
          this.EditRecipient();
        } else {
        }
      });
    },
  },
};
</script>
<style scoped>
.btn-danger {
  margin: 5px;
}
</style>